import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Button } from '../Button';
import { DateRange, Searchable } from '../Fields';
import { HookedForm } from '../HookedForm/HookedForm';
import { useBanner } from './Banner.hooks';
import { AutoCompletePlaceInterface } from '@app/models/places';
import { AnimatePresence, motion } from 'framer-motion';
import { preventDefault } from '@app/utils/utils';
import { ReactNode } from 'react';
import MapPinIcon from '@app/assets/icons/pin-map.svg?react';
import SearchIcon from '@app/assets/icons/search.svg?react';
import SecurePaymentIcon from '@app/assets/icons/secure-payment.svg?react';

export const Banner = ({
  simplified,
  disableLocation,
  clearFields = false,
  refetch,
  title = (
    <p>
      Stay, Earn, Repeat –<br />
      Bonuses for Every Hotel You Book
    </p>
  ),
  paragraph = (
    <p className="flex items-start md:items-center flex-col md:flex-row ">
      <span className="inline-flex items-center">
        <div>
          <SecurePaymentIcon className="mr-2" />
        </div>{' '}
        Secure payment
      </span>{' '}
      <span className="px-2 hidden md:inline">|</span>{' '}
      <span className="inline-flex items-center">
        <div>
          <SecurePaymentIcon className="mr-2" />
        </div>{' '}
        60,000+ locations
      </span>
    </p>
  ),
}: {
  title?: ReactNode;
  paragraph?: ReactNode;
  simplified?: boolean;
  disableLocation?: boolean;
  clearFields?: boolean;
  refetch?: () => void;
}) => {
  const {
    formHook,
    handleAdd,
    handleRemove,
    totalGuests,
    handleFormSubmit,
    adultGuests,
    childGuests,
    placesPredictions,
    isPlacePredictionsLoading,
    setSelectedLocation,
    handleRemoveChildAge,
    handleAddChildAge,
    recentSearches,
  } = useBanner(clearFields, refetch);

  return (
    <div>
      {!simplified && (
        <div className="rounded-2xl overflow-hidden h-[250px] md:h-[300px] relative">
          <div className="absolute inset-0 bg-black/30" />
          <div className="absolute bottom-20 px-8 pt-8 text-white">
            <h2 className="font-bold text-xl md:text-5xl">{title}</h2>
            <p className="text-sm md:text-lg">{paragraph}</p>
          </div>
          <img src="/images/background-banner.jpeg" alt="Random nature banner" className="h-full object-cover w-full" />
        </div>
      )}
      <div
        id="banner-search"
        className={`bg-white rounded-2xl z-20 relative px-6 py-4 ${simplified ? 'w-full' : '-mt-16 mx-8'}`}
      >
        <HookedForm
          formHook={formHook}
          onSubmit={handleFormSubmit}
          className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-none lg:grid-flow-col gap-2"
        >
          {!disableLocation && (
            <Searchable
              id="location"
              onSearch={(e) => {
                formHook.setValue('location', e.target.value);
              }}
              onChange={(e): void => {
                let locationValue;

                if (placesPredictions && placesPredictions.length > 0) {
                  locationValue = placesPredictions?.find(
                    (prediction) => prediction.place_id === e,
                  ) as unknown as AutoCompletePlaceInterface;
                } else {
                  const selectedRecent = recentSearches?.find((search) => search.value === e);
                  locationValue = {
                    description: selectedRecent?.label,
                    place_id: selectedRecent?.value,
                  } as AutoCompletePlaceInterface;
                }

                setSelectedLocation(locationValue);
                formHook.setValue('location', locationValue?.description);
              }}
              label="Location"
              name="location"
              placeholder="Where are you going?"
              inputClassName="relative py-2 rounded-lg !border-0 px-0"
              isFetching={isPlacePredictionsLoading}
              optionIcon={<MapPinIcon />}
              options={placesPredictions?.map((prediction) => ({
                label: prediction?.description,
                value: prediction?.place_id,
              }))}
              // recentSearches={recentSearches}
            />
          )}

          <DateRange name="dateIn" label="Check in" secondName="dateOut" secondLabel="Check out" />

          <div className="flex flex-col items-center gap-2">
            <div className="flex flex-col gap-2 w-full md:items-center">
              <label htmlFor="adults" className="font-bold">
                Guests
              </label>
              <Menu>
                {({ open }) => (
                  <div className="relative">
                    <MenuButton className="pb-2">
                      <span className="rounded-lg cursor-pointer w-full">{`${totalGuests} guest(s)`}</span>
                    </MenuButton>
                    <AnimatePresence>
                      {open && (
                        <MenuItems
                          as={motion.div}
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.95 }}
                          anchor="bottom"
                          className="origin-top absolute z-50 mt-2 min-w-fit bg-white p-4 rounded-lg shadow-md"
                        >
                          <MenuItem>
                            <div className="px-4">
                              <h4 className="font-bold text-dorrus-text-secondary">Room 1</h4>
                              <div className="flex gap-2 flex-col">
                                <div className="flex gap-10 justify-between items-center">
                                  <span>Adults</span>
                                  <div className="flex items-center gap-6">
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleRemove('adults'))}
                                      kind="pill"
                                    >
                                      -
                                    </Button>
                                    {adultGuests}
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleAdd('adults'))}
                                      kind="pill"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                                <div className="flex gap-10 justify-between items-center">
                                  <span>Children</span>
                                  <div className="flex items-center gap-6">
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleRemove('children'))}
                                      kind="pill"
                                    >
                                      -
                                    </Button>
                                    {childGuests}
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleAdd('children'))}
                                      kind="pill"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>

                                {Array.from({ length: Number(childGuests) ?? 0 }).map((_, index) => {
                                  const child = formHook.watch('childAges');
                                  return (
                                    <div key={index} className="flex gap-10 justify-between items-center">
                                      <span>Child {index + 1} age</span>
                                      <div className="flex items-center gap-6">
                                        <Button
                                          type="button"
                                          onClick={(e) => preventDefault(e, () => handleRemoveChildAge(index))}
                                          kind="pill"
                                        >
                                          -
                                        </Button>
                                        {child ? child[index] : 1}
                                        <Button
                                          type="button"
                                          onClick={(e) => preventDefault(e, () => handleAddChildAge(index))}
                                          kind="pill"
                                        >
                                          +
                                        </Button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </MenuItem>
                        </MenuItems>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </Menu>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button kind="secondary" type="submit" className="w-full md:w-max px-6" leftNode={<SearchIcon />}>
              Search
            </Button>
          </div>
        </HookedForm>
      </div>
    </div>
  );
};
