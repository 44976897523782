import { useBanner } from "@app/components/Banner/Banner.hooks";
import { useSearch } from "@app/context/search";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

export const useResults = () => {
  const [showMap, setShowMap] = useState(false);
  const { searchDispatch, fetchResults, filters } = useSearch();
  const { clearLocation } = useBanner();
  const formHook = useForm({
    defaultValues: {
      ...filters,
      SortBy: {
        label: "Distance from search",
        value: { SortBy: "DistanceFrom", SortOrder: "ASC" },
      },
    },
    mode: "onChange",
  });

  const handleSort = (e: FieldValues) => {
    searchDispatch({
      type: "updateFilters",
      filters: {
        ...filters,
        ...(e?.SortBy && e?.SortBy?.value && { ...e?.SortBy?.value }),
      }      
    });
  };

  const focusOnLocation = () => {
    const locationInput = document.querySelector('[data-id="location"]');
    if (locationInput) {
      (locationInput as HTMLInputElement)?.focus();
      (locationInput as HTMLInputElement).value = "";
      clearLocation();
    }
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  return {
    fetchResults,
    searchDispatch,
    toggleMap,
    showMap,
    focusOnLocation,
    formHook,
    handleSort,
  };
};
