import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';
import { SearchParamsFiltersInterface, SearchParamsInterface } from '@app/models/search';
import { toast } from 'react-toastify';

export const getHotels = async (
  params: Partial<
    SearchParamsInterface & { data: object; PageSize: number; OffSet: number; unitOfMeasure: string } & {
      filters: SearchParamsFiltersInterface;
    }
  >,
) => {
  
  return await apiInstance
    .post(`${ApiRoutes.BOOKING}/getHotels`, {
      ...params,
      ...params.data,
      ...params.filters,
      searchRadius: params.searchRadius || 50,
      unitOfMeasure: params.unitOfMeasure ?? 'MI',
      currencyCode: 'USD',
      OffSet: params.OffSet || 1,
      PageSize: params.PageSize || 10,
      SortBy: params.SortBy || 'DistanceFrom'
    })
    .then((res) => {
      if (res?.data?.lodgingAvailRS?.availResults) {
        const hasNextPage = (params?.PageSize ?? 5) * (params?.OffSet ?? 1) < res?.data?.lodgingAvailRS?.maxResults;
        const hasPreviousPage = (params?.OffSet ?? 1) > 1;
        return {
          results: res?.data?.lodgingAvailRS?.availResults?.availDetails?.flat(),
          maxResults: res?.data?.lodgingAvailRS?.maxResults,
          hasNextPage,
          hasPreviousPage,
        };
      }
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error ?? err?.data?.error ?? 'Error while getting list of hotels available.');
      toast.clearWaitingQueue();
    });
};

export const getHotelRates = async (data?: { sourceRateCode: string }) => {
  return apiInstance.post(`${ApiRoutes.BOOKING}/getHotelRates`, data);
};

export const getHotelRatesByDate = async (
  hotelId: string,
  context?: string,
  withDetails?: boolean,
  searchParams?: Partial<SearchParamsInterface>,
) => {
  if (searchParams) {
    const { adults, children, inDate, outDate, ChildAges } = searchParams;
    return apiInstance.post(`${ApiRoutes.BOOKING}/getHotelRatesByDate/${hotelId}`, {
      context,
      withDetails,
      adults,
      children,
      inDate,
      outDate,
      ChildAges,
      currencyCode: 'USD',
    });
  }
  return undefined;
};

export const getHotelDetails = async (data: { hotelCode: string }) => {
  return apiInstance.post(`${ApiRoutes.BOOKING}/getHotel`, data);
};

export const getOrders = async () => {
  return apiInstance.get(`${ApiRoutes.BOOKING}/orders`);
};

export const getPastOrders = async () => {
  return apiInstance.get(`${ApiRoutes.BOOKING}/old-orders`);
};

export const getCanceledOrders = async () => {
  return apiInstance.get(`${ApiRoutes.BOOKING}/canceled-orders`);
};

export const getBookingById = async (id: string, lastName?: string) => {
  return apiInstance.get(`${ApiRoutes.BOOKING}/getBooking/${id}/${lastName}`);
};

export const postBooking = async (data: {
  sourceRateCode: string;
  email: string;
  phoneNumber?: string;
  cardId: string;
  travelers: { givenName: string; surname: string; passengerCode: string }[];
  loyaltyPrograms?: { supplierCode: string; programNumber: string };
}) => {
  return apiInstance.post(`${ApiRoutes.BOOKING}/reserve`, {
    ...data,
    ...(data?.loyaltyPrograms && { loyaltyPrograms: { ...data?.loyaltyPrograms, programType: 'LOYALTY_ID' } }),
  });
};

export const updateBooking = async (
  id: string,
  data: { checkInDate: string; checkOutDate: string; roomProductCode: string; sourceRateCode: string },
) => {
  return apiInstance.put(`${ApiRoutes.BOOKING}/update/${id}`, data);
};

export const cancelBooking = async (sabreID: string) => {
  return apiInstance.put(`${ApiRoutes.BOOKING}/cancel/${sabreID}`);
};


export const getChainCodes = async () => {
  return apiInstance.post(`${ApiRoutes.BOOKING}/getHotelChains`);
};