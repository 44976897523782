import { useState, useEffect } from "react";
import { Range, Select } from "../Fields";
import { HookedForm } from "../HookedForm/HookedForm";
import {
  amenitiesList,
  cancellationPolicy,
  mealList,
  paymentType,
  propertyClass,
  reviewScore,
} from "@app/consts/filters";
import { useFilters } from "./Filters.hooks";
import { HotelInterface } from "@app/models/search";
import { RadiusRange } from "../Fields/RadiusRange";
import { getChainCodes } from "@app/services/booking";
import { transformChainsData } from "@app/context/search/utils";

export const Filters = ({
  results,
  refetch,
}: {
  results: HotelInterface[];
  refetch: () => void;
}) => {
  const { formHook, max, handleChange } = useFilters(results, refetch);
  const [chains, setChains] = useState<{ chainCode: string; label: string }[]>(
    []
  );

  useEffect(() => {
    const fetchChains = async () => {
      try {
        const response = await getChainCodes();
        const transformedChains = transformChainsData(response);
        setChains(transformedChains);
      } catch (error) {
        console.error("Failed to fetch hotel chains:", error);
        setChains([]);
      }
    };
    fetchChains();
  }, []);

  return (
    <div className="my-6">
      <HookedForm
        formHook={formHook}
        onChange={handleChange}
        className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-none xl:grid-flow-col gap-4"
      >
        <Range
          min={0}
          max={max ?? 15000}
          placeholder="Budget"
          name="RateRange"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
        />
        <Select
          keepPlaceholder
          placeholder="Review score"
          name="Rating"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={reviewScore}
        />
        <Select
          placeholder="Property class"
          name="PropertyQualityCode"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={propertyClass}
        />
        <Select
          keepPlaceholder
          placeholder="Payment type"
          name="RefundableOnly"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={paymentType}
        />
        <Select
          keepPlaceholder
          placeholder="Meal plans available"
          name="meals"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={mealList}
        />
        <Select
          multiple
          placeholder="Amenities"
          name="AmenityCode"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={
            amenitiesList.map((amenity) => ({
              label: amenity.name,
              value: amenity.externalCode,
            })) ?? []
          }
        />
        <Select
          placeholder="Cancellation policy"
          name="RefundableOnly"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={cancellationPolicy}
        />
        <Select
          multiple
          placeholder="Hotel Chains"
          name="ChainCode"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={
            chains?.map((chain) => ({
              label: chain.label,
              value: chain.chainCode.toString(),
            })) ?? []
          }
        />
        <RadiusRange
          name="searchRadius"
          label="Radius"
          placeholder="Radius"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
        />
      </HookedForm>
    </div>
  );
};
